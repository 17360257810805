import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Vendor, NotFoundComponent } from "./modules/vendorPages";

import "./App.css";

const App = () => {
  return (
    <div className="App">
      {/* <div className="container"> */}
      {/* 1st row 2 columns */}
      <Router>
        <Switch>
          <Route path="/brand/:shop/:brandName" component={Vendor} />
          <Route component={NotFoundComponent} />
        </Switch>
      </Router>
      {/* </div> */}
    </div>
  );
}

export default App;
