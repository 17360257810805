import React from "react";
import Image from "react-bootstrap/Image";
import baseHelper from "../../../../../helper/base";

const Header = props => {
  const { brandDetail } = props;
  const { brandCover = {} } = brandDetail || {};
  const defaultCoverImage = baseHelper.getImagePath("bg.png");

  return (
    <header>
      {/* <div className="top-bar" /> */}
      <div className="poster-img">
        <Image src={brandCover.imageUrl || defaultCoverImage} alt="header poster" fluid />
      </div>
    </header>
  );
};
export default Header;
