const apiURL = process.env.REACT_APP_API_URL || "http://dev-proxy.marketcube.io"; // "http://localhost:9020/api";
const publicHeader = {
  Host: "public",
  apikey: process.env.REACT_APP_API_KEY || "e8f571103d3a41f3a53d4943b693f36f",
};

const proxyURL =
  process.env.REACT_APP_PROXY_URL || "https://us-central1-marketcube-dev.cloudfunctions.net/dev-proxy-server";

const clientURL = process.env.REACT_APP_CLIENT_URL || "http://localhost:3000";

const config = {
  apiURL,
  publicHeader,
  clientURL,
  proxyURL,
};

export default config;
