import React from "react";
import baseHelper from "../../../../../helper/base";

const NotFoundComponent = () => {
  const defaultImageUrl = baseHelper.getImagePath('notFound.jpg');

  return (
    <div>
      <img src={defaultImageUrl} alt="" className="fullPageSpinner" />
    </div>
  );

};
export default NotFoundComponent;
