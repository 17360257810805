import React from "react";
// import { BrowserRouter as Router, Link } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";

const GalleryHeader = props => {
  const { handleSortValueChange, handleSearchChange, searchValue, productCount = 0 } = props;
  return (
    <Container>
      <h5 className="text-uppercase text-left mb-4">{`Products (${productCount})`}</h5>
      <Row className="filter-toolbar align-items-center mb-4">
        {/* <Col lg={4} xs={12} className="heading-gallery margin-top">
          
        </Col> */}
        <Col md={3} xs={8}>
          <div className="form-group has-search w-100 mb-0 position-relative">
            <i className="fa fa-search search-icon" aria-hidden="true"></i>
            <input type="text" className="form-control" value={searchValue} onChange={handleSearchChange} />
          </div>
        </Col>
        <Col md={4} xs={4} className="offset-md-5">
          <ListGroup horizontal className="social gallery-icons p-0">
            {/* <ListGroup.Item className="gallery-bg">
              <Image src="../assets/Icon_open-grid-two-up.svg" alt="fb" />
            </ListGroup.Item>
            <ListGroup.Item className="gallery-bg">
              <Image src="../assets/Icon_awesome-list.svg" alt="fb" />
            </ListGroup.Item>
            */}
            <ListGroup.Item className="sort-drop p-0">
              {/* <label>Sort by</label> */}
              <DropdownButton alignRight title="Sort By" id="dropdown-menu-align-right" className="dropdown-margin">
                <Dropdown.Item eventKey="1" onSelect={() => handleSortValueChange("createdAt", "asc")}>
                  Date (Asc)
                </Dropdown.Item>

                <Dropdown.Item eventKey="2" onSelect={() => handleSortValueChange("createdAt", "desc")}>
                  Date (Desc)
                </Dropdown.Item>

                <Dropdown.Item eventKey="3" onSelect={() => handleSortValueChange("title", "asc")}>
                  Product Title (Asc)
                </Dropdown.Item>

                <Dropdown.Item eventKey="4" onSelect={() => handleSortValueChange("title", "desc")}>
                  Product Title (Desc)
                </Dropdown.Item>
              </DropdownButton>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};
export default GalleryHeader;
