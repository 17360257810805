import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import axios from "axios";
import config from "../../../../config/config";
import baseHelper from "../../../../helper/base";
import { Header, Gallery, Tabsrow, GalleryHeader, Footer } from "../index";

const Vendor = props => {
  const { match, history } = props;
  const { params, url } = match;
  const { brandName, shop } = params;
  const { apiURL } = config;

  const urlString = window.name;
  const parentURL = baseHelper.isValidURL(urlString) ? urlString : "";

  let queryParams = history.location.search || "";

  let uri = `${apiURL}/public/brand/${shop}/${brandName}`;

  const [state, setState] = useState({});
  const [productsList, setProductList] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState({
    sort_name: "",
    sort_order: "",
  });

  const [currentPage, setCurrentPage] = useState(1);

  const [brandLoading, setBrandLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [error, setError] = useState(false);

  const setQueryParams = params => {
    const { location } = history;
    const search = baseHelper.getQueryParams(location, params);
    history.push(`${location.pathname}?${search}`);
  };

  const unsetQueryParams = key => {
    const { location } = history;
    const { search } = location;
    const paramSearch = search.indexOf(key);

    if (paramSearch > -1) {
      const updateUrl = baseHelper.removeQueryParams(location, key);
      history.push(`${updateUrl}`);
    }

    setCurrentPage(1);
  };

  const handleSortValueChange = (name, order) => {
    setSortValue({ sort_name: name, sort_order: order });
    const sortQueryParams = { sort_name: name, sort_order: order };
    setQueryParams(sortQueryParams);
  };

  const handleSearchChange = e => {
    const searchedValue = e.target.value;
    setSearchValue(searchedValue);
    const searchQueryParams = { search: searchedValue };
    unsetQueryParams("page");
    setQueryParams(searchQueryParams);
  };

  const handleCurrentPageValue = key => {
    let pageValue = currentPage;
    if (key === "previous") {
      pageValue -= 1;
    }

    if (key === "next") {
      pageValue += 1;
    }
    setCurrentPage(pageValue);
    const pageQueryParams = { page: pageValue };
    if (pageValue === 1) {
      history.push(`${url}`);
      return;
    }
    setQueryParams(pageQueryParams);
  };

  const fetchProductDetails = async () => {
    setProductLoading(true);
    try {
      const getProductUrl = `${apiURL}/public/brand/product/${shop}/${brandName}${queryParams}`;

      const requestParams = { uri: getProductUrl };
      const options = baseHelper.getRequestOptions(requestParams);

      const response = await axios(options);

      if (response.data && response.data.status === "ok") {
        const { products = [], productCount } = response.data.data || response.data;
        setProductList({ ...productsList, products, search: productCount });
      }
      setProductLoading(false);
    } catch (err) {
      console.error("Error is: ", err.message);
      setProductLoading(false);
    }
  };

  const getUserDetails = async updateUrl => {
    setBrandLoading(true);
    try {
      const requestParams = { uri: updateUrl };
      const options = baseHelper.getRequestOptions(requestParams);
      const response = await axios(options);
      // console.log(response.data);

      // const response = await axios.get(url, { headers: publicHeader });
      if (response.data && response.data.status === "ok") {
        const { user, productCount, orderCount, brandDetail = {}, url, store = {} } = response.data.data || response.data;
        setState({ ...state, user, productCount, orderCount, brandDetail, url, store });

        fetchProductDetails();
      }
      setBrandLoading(false);
    } catch (err) {
      console.error("Error is: ", err.message);
      setBrandLoading(false);
      setError(true);
    }
  };

  const getQueryParamsArray = params => {
    const paramObj = baseHelper.splitQueryParams(params);
    if (paramObj && paramObj.search) {
      setSearchValue(paramObj.search);
    }
  };

  useEffect(() => {
    if (brandName) {
      getUserDetails(uri);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandName]);

  useEffect(() => {
    if (sortValue || searchValue || currentPage) {
      fetchProductDetails();
    }
    if (queryParams) {
      getQueryParamsArray(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue, searchValue, currentPage, queryParams]);

  const Spinner = () => <ReactLoading className="fullPageSpinner" type="bars" color="#5968bf" />;
  const productSpinner = () => <ReactLoading className="productPageSpinner" type="bars" color="#5968bf" />;

  if (error) {
    return <p>No User found</p>;
  }

  return (
    <>
      {(brandLoading && Spinner()) || (
        <>
          <Header brandDetail={state.brandDetail} />
          <div className="content-wrapper">
            <Tabsrow
              vendorInfo={state.user}
              orderCount={state.orderCount}
              brandDetail={state.brandDetail}
              url={state.url}
              shop={shop}
              store={state.store}
            />
            {/* 2nd row 4 columns */}
            <div className="gallery-bg pb-5">
              <GalleryHeader
                className="margin-top"
                searchValue={searchValue}
                handleSortValueChange={handleSortValueChange}
                handleSearchChange={handleSearchChange}
                productCount={state.productCount}
              />
              {(productLoading && productSpinner()) || (
                <Gallery
                  parentURL={parentURL}
                  products={productsList.products}
                  productCount={productsList.search}
                  currentPage={currentPage}
                  store={state.store}
                  handleCurrentPageValue={handleCurrentPageValue}
                />
              )}
            </div>
          </div>

          {/* <Footer /> */}
        </>
      )}
    </>
  );
};
export default Vendor;
