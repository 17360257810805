import React, { useState } from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { tabsContent, vendorInfo } from "../../../../../cms";
import baseHelper from "../../../../../helper/base";
import config from "../../../../../config/config";

const imagePath = baseHelper.getImagePath();

const Tabsrow = props => {
  const { vendorInfo, brandDetail = {}, url, shop, store = {} } = props; // productCount = 0,
  const { socialLink = {}, description = "", brand = {} } = brandDetail || {};
  const defaultImageUrl = `${imagePath}vendor.svg`;

  const [isHideContact] = useState(!!store.isHideVendorContact);

  if (!vendorInfo) {
    return "No User Found";
  }

  const email = vendorInfo.email && vendorInfo.email.address;

  // const { vendorInfo, brandDetail = {}, url } = props;
  // const { socialLink = {}, description = "", brand = {} } = brandDetail || {};
  // const defaultImageUrl = `${imagePath}vendor.svg`;

  // console.log(props);

  return (
    <Container>
      <Row className="mc-about">
        <div className="container-wrapper">
          <div className="wrapper mb-4">
            <Col md={8} className="p-4 order-2 order-md-1">
              <Col className="d-flex w-100 mb-4 pl-0">
                <div className="brand-name weglot-ignore">
                  {/* <h3 className="mr-2">vendorInfo.vendor</h3> */}
                  <h3 className="mr-2 text-capitalize">{vendorInfo.brandName}</h3>
                  <span className="text-left d-block mb-0">{vendorInfo.city}</span>
                </div>

                <div className="claim-btn ml-auto pt-3 pt-sm-0 text-right">
                  {!(email || vendorInfo.isInactive) && (
                    <Button
                      href={`${url || config.clientURL}/claim/${shop}/${vendorInfo._id}`}
                      type="button"
                      className="btn btn-warning"
                    >
                      Claim business
                    </Button>
                  )}
                  {/* {vendorInfo && vendorInfo.email.verified && (
                  <Button href={`${url || config.clientURL}/register`} type="button" className="btn btn-warning">
                    Claim business
                  </Button>
                )} */}
                </div>
              </Col>

              <Tabs defaultActiveKey="about" id="uncontrolled-tab-example" className="brand-info">
                <Tab eventKey="about" title={!isHideContact && "About"} className="contact-info weglot-ignore">
                  {description}
                </Tab>
                {!isHideContact && (
                  <Tab eventKey="contact" title="Contact" className="contact-info weglot-ignore">
                    <address className="address mb-0 text-left">
                      {/* <h4 className="font-weight-bold mb-4">Contact info</h4> */}
                      {/* <strong>Company: </strong> */}
                      <span className="company">{vendorInfo.address}</span>
                      <br />
                      {/* <strong>Email: </strong> */}
                      <span>{email && <a href={`mailto:${email}`}>{email}</a>}</span>
                      <br />
                      {/* <strong>Address: </strong> */}
                      <span className="addrs">
                        {`${vendorInfo.city},
                      ${vendorInfo.country}`}
                      </span>
                      <br />
                      {/* <strong>Website: </strong>
                      <span className="website">
                        <Link to="www.google.com">www.Google.com</Link>
                      </span> */}

                      {/* <strong>Name: </strong> */}
                      <span>{`${vendorInfo.firstName} ${vendorInfo.lastName}`.trim()}</span>

                      {/* <span>
                        <a href={`mailto:${vendorInfo.email.address}`}>
                          {vendorInfo.email.address}
                        </a>
                      </span> */}
                      <br />
                      {/* <strong>Phone: </strong> */}
                      <span>{vendorInfo.phoneNumber}</span>
                    </address>
                  </Tab>
                )}
              </Tabs>

              <Col className="about-des">
                {/* <p>{description}</p> */}
                {/* <Router>
                <Link className="btn btn-primary btn-lg" to="#">
                  Call to Action »
                </Link>
              </Router> */}
                <div className="social-info">
                  <ListGroup horizontal className="info">
                    {/* <ListGroup.Item>
                    <span>{productCount}</span>
                    <br />
                    {tabsContent.products}
                  </ListGroup.Item> */}
                    {/* <ListGroup.Item>
                    <span style={{ color: "#30b774" }}>{tabsContent.ratingno}</span>
                    <br />
                    {tabsContent.rating}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <span>{(orderCount && orderCount.total) || 0}</span>
                    <br />
                    {tabsContent.orders}
                  </ListGroup.Item>
                */}
                  </ListGroup>
                  {socialLink && (
                    <ListGroup horizontal className="social">
                      {socialLink.facebook && (
                        <ListGroup.Item action active={false} href={socialLink.facebook} target="_blank">
                          <Image src={`${imagePath}shape_3.svg`} alt="facebook" fluid />
                        </ListGroup.Item>
                      )}
                      {socialLink.linkedin && (
                        <ListGroup.Item action href={socialLink.linkedin} target="_blank">
                          <Image src={`${imagePath}shape_4.svg`} alt="linkedin" fluid />
                        </ListGroup.Item>
                      )}
                      {socialLink.instagram && (
                        <ListGroup.Item action href={socialLink.instagram} target="_blank">
                          <Image src={`${imagePath}shape_5.svg`} alt="instagram" fluid />
                        </ListGroup.Item>
                      )}
                      {socialLink.youtube && (
                        <ListGroup.Item action href={socialLink.youtube} target="_blank">
                          <Image src={`${imagePath}shape_6.svg`} alt="youtube" fluid />
                        </ListGroup.Item>
                      )}
                      {socialLink.twitter && (
                        <ListGroup.Item action action href={socialLink.twitter} target="_blank">
                          <Image src={`${imagePath}shape_7.svg`} alt="twitter" fluid />
                        </ListGroup.Item>
                      )}
                    </ListGroup>
                  )}
                </div>
              </Col>
            </Col>

            <Col md={4} className=" logo-desc p-4 order-1 order-md-2">
              <div className="logo text-left text-md-right">
                <Image src={(brand && brand.imageUrl) || defaultImageUrl} alt="logo" />
              </div>
            </Col>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Tabsrow;
