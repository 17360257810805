import React from "react";
// import { BrowserRouter as Router, Link } from "react-router-dom";
import uuid from "react-uuid";
// import { Stars } from "../../../../../components/stars";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import baseHelper from "../../../../../helper/base";

const Gallery = props => {
  const { products, handleCurrentPageValue, parentURL, currentPage = 1, productCount = 0, store = {} } = props;
  const defaultImageUrl = baseHelper.getImagePath("Image_7.svg");

  const productPerPage = 20;
  let totalPages = 1;

  if (productCount) {
    totalPages = Math.ceil(productCount / productPerPage);
  }

  const getRowWiseData = list => {
    const updatedList = [...list];
    const resultList = [];

    while (updatedList.length) {
      resultList.push(updatedList.splice(0, 4));
    }

    return resultList;
  };

  const getMappedData = list => {
    const mappedList = [];

    const url = parentURL ? `${parentURL}/products` : "";

    list.forEach(arr => {
      const { moneyFormat = "$" } = store || {};
      const row = arr.map(item => {
        const { _id, title, description = "", handle, price, primaryImageUrl, shopifyProductId } = item; // variants

        let productURL = "";

        if (url && handle && shopifyProductId) {
          productURL = `${url}/${handle}`;
        }

        return (
          <Col xs={6} lg={3} key={`productParentDiv${_id}`} className="mb-4 position-relative">
            <div className="card h-100">
              {(productURL && (
                <a href={productURL} target="_blank" className="position-relative img-wrapper d-block">
                  <img className="card-img-top" src={primaryImageUrl || defaultImageUrl} alt="" />
                </a>
              ))|| (
                <span className="position-relative img-wrapper d-block">
                  <img className="card-img-top" src={primaryImageUrl || defaultImageUrl} alt="" />
                </span>
              )}

              <div className="card-body">
                <h5 className="card-title text-capitalize">
                  {(productURL && (
                    <a href={productURL} target="_blank">
                      {title}
                    </a>
                  )) ||
                    title}
                </h5>
                <p className="card-text">{description}</p>
                <span className="cost d-block">{`${moneyFormat} ${price || 0.0}`}</span>
              </div>

              {/* <span className="variants">
                Variants:
                {variants.length}
              </span> */}

              {/* <div className="card-footer">
                <Router>
                  <Link to="#" className="btn btn-light">
                    Add to cart
                  </Link>
                </Router>
              </div> */}
            </div>
          </Col>
        );
      });
      mappedList.push(row);
    });

    return mappedList;
  };

  const productLists = () => {
    if (products && products.length) {
      const formattedData = getRowWiseData(products);
      const mappedData = getMappedData(formattedData);

      return mappedData.map(item => {
        return (
          <Row className="gallery weglot-ignore" key={uuid()}>
            {item}
          </Row>
        );
      });
    }
    return <div>No Published Products found</div>;
  };

  return (
    <Container>
      {productLists()}
      {(totalPages > 1 && (
        <ul className="pagination mb-0 justify-content-center">
          {currentPage !== 1 && (
            <li className="page-item">
              <button type="button" onClick={() => handleCurrentPageValue("previous")}>
                <i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                {/*  disabled={currentPage === 1} */}
              </button>
            </li>
          )}
          {/* <div className="pro-load-more load-more-border text-center mt-30">
            <Link className="load-more-toggle default-btn btn-hover position-relative" to="#">
              Load More Products
            </Link>
          </div> */}
          {currentPage !== totalPages && (
            <li className="page-item">
              <button type="button" onClick={() => handleCurrentPageValue("next")}>
                {/* disabled={currentPage === totalPages} */}
                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
              </button>
            </li>
          )}
        </ul>
      )) ||
        ""}
    </Container>
  );
};

export default Gallery;
